import * as React from 'react';
import { FC } from 'react';

import { TimeSeries } from '@/types/TimeSeries';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate, formatNumber } from '@/utils/FormattingUtils';

interface ChartProps {
  timeSeries: TimeSeries[];
}

const CustomTooltip = ({ active, payload }: any) => {
  const formattedValue = formatNumber(payload?.[0]?.value);
  const formattedDate = formatDate(new Date(payload?.[0]?.payload.date));

  if (active && payload && payload.length) {
    return (
      <div className="px-5 py-2 bg-white flex w-[300px] items-center justify-between text-black">
        <div className="text-base w-1/2">{formattedDate}</div>
        <div className="text-2xl font-bold w-1/2 text-right">BVIV&nbsp;{formattedValue}</div>
      </div>
    );
  }

  return null;
};

const Chart: FC<ChartProps> = ({ timeSeries }) => (
  <ResponsiveContainer height={"100%"} className={"absolute top-0 left-0"}>
    <AreaChart
      data={timeSeries}
    >
      <defs>
        <linearGradient id="areaGradient" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor="#00FFB2" stopOpacity={0} />
          <stop offset="100%" stopColor="#00FFB2" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="axisLineGradient" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity={0} />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Line
        type="monotone"
        dataKey="close"
        stroke="#8884d8"
        dot={{ stroke: `#00FFB2`, strokeWidth: 7, r: 6, fill: `black` }}
      />
      {/* <XAxis hide /> */}
      <YAxis
        // hide
        type="number"
        mirror
        allowDecimals
        tickCount={3}
        tickLine={false}
        // tickMargin={5}
        padding={{ top: 5, bottom: 5 }}
        scale="linear"
        orientation="right"
        domain={[45, 80]}
        tickFormatter={formatNumber}
        axisLine={{ stroke: `#B8C9C8`, strokeWidth: 0.3 }}
        style={{
          fontSize: `0.7rem`,
        }}
      />
      <Tooltip
        content={<CustomTooltip />}
        wrapperStyle={{ border: 0, outline: `none`, zIndex: 1000 }}
      />
      <Area
        type="monotone"
        dataKey="close"
        stroke="url(#axisLineGradient)"
        fillOpacity={1}
        fill="url(#areaGradient)"
        activeDot={{
          r: 6,
          fill: `#00FFB2`,
        }}
      />
      <CartesianGrid stroke="rgba(255,255,255,0.1)" strokeDasharray="3 3"/>
    </AreaChart>
  </ResponsiveContainer>
);

export default Chart;
