import * as React from 'react';
import styled from 'styled-components';
import { Heading, Subtitle } from '@/styles/global-styled-components';
import VivIndex from '@/components/Home/Main/VivIndex';
import Buttons from '@/components/Home/Main/Buttons';
import BackgroundImage from '@/components/Home/Main/BackgroundImage';
// import Carousel from '@/components/Home/Main/Carousel';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import PerpetualsTraded from './PerpetualsTraded';
import Title from './title';
import ChartSection from './ChartSection';

const ViewportHeight = styled.div`
  @media ${(props) => `${props.theme.screens.landscape.raw}`} {
    min-height: 700px;
    height: 97vh;
    max-height: -webkit-fill-available; // for safari
  }
`;
// 
const Main = () => {
  const { width } = useWindowDimensions();
  

  return (
    <ViewportHeight className="page-padding bg-black flex flex-col justify-between relative overflow-hidden">
      <div className='relative'>
        <div className="z-10 flex flex-col pt-40">
          <Title/>
          <Buttons />
        </div>
        <ChartSection/>
        <div className="flex justify-between items-end">
          <VivIndex />
          {/* if you try to use css to do this, the text of the carousel disappears, when going from mobile to desktop width screen size */}
          {width > 1024 && <PerpetualsTraded />}
        </div>

      </div>
    </ViewportHeight>
  );
};

export default Main;
