import * as React from 'react';
import { FunctionComponent, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Heading } from '@/styles/global-styled-components';
import { BeatLoader } from 'react-spinners';
import { IndexDataContext } from '@/context/IndexData';
import PerpetualsTraded from './PerpetualsTraded';

interface AssetProps {
  label: string;
  value: number;
  hasIncreased?: boolean;
}

const AssetIndex: FunctionComponent<AssetProps> = ({
  label,
  value,
  hasIncreased,
}) => (
  <Heading className="flex font-black">
    <span>{label}</span>
    <span className="text-sm pl-2 pt-1 font-thin">®</span>
    <span
      className={classNames(`ml-8`, {
        'text-green-200': hasIncreased,
        'text-pink': !hasIncreased,
      })}
    >
      {hasIncreased ? `↑` : `↓`} {value}
    </span>
  </Heading>
);

const VivIndex = () => {
  const [currentEth, setCurrentEth] = React.useState(0);
  const [currentBtc, setCurrentBtc] = React.useState(0);
  const [hasIncreasedEth, setHasIncreasedEth] = React.useState(true);
  const [hasIncreasedBtc, setHasIncreasedBtc] = React.useState(true);

  const { btcTimeseries: btcData, ethTimeseries: ethData } =
    useContext(IndexDataContext);

  const loadingBtc = !btcData;
  const loadingEth = !ethData;
  useEffect(() => {
    // restricting to 2 decimal places to restrict the hasIncreased flag from changing continuously
    const formattedBtcValue = btcData?.close?.toFixed(2);
    if (formattedBtcValue !== currentBtc) {
      setHasIncreasedBtc(formattedBtcValue >= currentBtc);
      setCurrentBtc(formattedBtcValue);
    }

    const formattedEthValue = ethData?.close?.toFixed(2);
    if (formattedEthValue !== currentEth) {
      setHasIncreasedEth(formattedEthValue >= currentEth);
      setCurrentEth(formattedEthValue);
    }
  }, [btcData, ethData]);

  if (loadingBtc || loadingEth) {
    return (
      <BeatLoader color="white" className="!flex items-center w-full h-full" />
    );
  }

  if (!currentEth || !currentBtc) {
    return null;
  }

  return (
    <div className="flex flex-col border-l-4 pb-8 pl-8 mt-8">
      <div className="text-2xl mb-2">Volmex Implied Volatility Indices</div>
      <AssetIndex
        label="BVIV"
        value={currentBtc}
        hasIncreased={hasIncreasedBtc}
      />
      <AssetIndex
        label="EVIV"
        value={currentEth}
        hasIncreased={hasIncreasedEth}
      />
      <div className="block lg:hidden -mb-8 mt-8">
        <PerpetualsTraded />
      </div>
    </div>
  );
};

export default VivIndex;
