import React from "react";
import { motion } from "framer-motion";

const AnimatedText = ({ text, onAnimationComplete }) => {
  // Split the text into words
  const words = text.split(" ");

  // Variants for character animation
  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        duration: 5,
        staggerChildren: 0.05, // Delay between each character
      },
    },
  };

  const characterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="max-w-[800px] absolute sm:text-[80px] text-[70px] font-black"
      style={{
        display: "flex",
        flexWrap: "wrap", // Ensure text wraps between words
        whiteSpace: "normal", // Allow normal text wrapping
      }}
      variants={containerVariants}
      onAnimationComplete={(latest) => onAnimationComplete(latest)}
    >
      {words.map((word, wordIndex) => (
        <motion.span
          key={wordIndex}
          style={{
            display: "inline-block", // Ensure words wrap correctly
            marginRight: "0.25em", // Add space between words
          }}
        >
          {word.split("").map((char, charIndex) => (
            <motion.span
              key={charIndex}
              style={{ display: "inline-block" }} // Ensure characters stay inline
              variants={characterVariants}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default AnimatedText;