import React, { useRef } from 'react';
import AnimatedText from '@/components/animatedText';
import { useAnimation, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Button } from '@/components/Base/Button';
import { Heading } from '@/styles/global-styled-components';

export default function Title () {
  const controls = useAnimation()
  const [title, setTitle] = useState(0)

  const timeoutID = useRef<NodeJS.Timeout | null>(null);

  const titles = [
    `Leading Crypto Volatility Indices and Volatility Products.`,
    `The Bitcoin Volatility Index Standard.`,
    `BVIV Index (Bitcoin Volmex Implied Volatility)`,
    `The Crypto Interest Rate Standard.`,
    `VPR Indices (Volmex Prime Rate Indices)`,
    `Volmex is the Index Conglomerate of the Future.`,
  ]

  const test = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 5,
      }
    }
  }
  
  const onAnimationComplete = (variantName) => {
    if (variantName != "visible") return
    console.log("complete")
    controls.start("hidden")

    // Clear any existing timeout before setting a new one
    if (timeoutID.current) clearTimeout(timeoutID.current);

    timeoutID.current = setTimeout(() => {
      setTitle((title + 1) % 6)
      controls.start("visible")
    }, 1000)
    
  }

  useEffect(() => {
    controls.start("visible")

    // Cleanup timeout when component unmounts
    return () => {
      if (timeoutID.current) clearTimeout(timeoutID.current);
    };
  }, [])

  return (
    <Heading className="flex flex-row pb-6 xl:min-h-[350px] lg:min-h-[330px] md:min-h-[270px] min-h-[530px]">
      <motion.div
        variants={test}
        initial="hidden"
        animate={controls}
      >
        <AnimatedText text={titles[title]} onAnimationComplete={onAnimationComplete}/>
      </motion.div>
    </Heading>
  
  )
}